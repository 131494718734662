body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav{
  background-color: black;
}

h1{
  font-size: 30px;
}

.collapsible-body{
  display: block;
}

.collapsible-header{
  background: #f5f5f5;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.collapsible-body{
  padding: 0;
  padding-left: 20px;
  border: 0;
}

.row{
  width: 100%;
}

i{
  font-size: 20px!important;
  margin-right: 0!important;
}

.input{
  border: solid 1px #ccc!important;
  padding-left: 20px!important;
  width: -webkit-fill-available!important;
  /* max-width: 400px; */
  margin:auto!important;
  display: table;
  margin-bottom: 10px;
}

table tr td{
border : 1px solid #fff;
}